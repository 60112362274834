import React from 'react'

import SEO from '~/components/seo'
import About from '../components/About'
import Footer from '../components/Footer'

const AboutPage = () => (
  <>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
    <About />
    <Footer />
  </>
)

export default AboutPage
