import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { HeadingText, BodyText } from '../../styles'
import media from '../../styles/media'

const AboutMoreCon = styled.div`
  height: 800px;
  overflow: hidden;
  .fade-in-section {
    opacity: 0;
    transform: translateY(2vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  .hero-image {
    position: absolute !important;
    overflow: hidden;
    width: 100%;
    height: 800px;
    z-index: -1;
  }
  .text {
    margin: var(--gutter-l) var(--gutter-s);
  }
  ${media.laptop`
    height: 800px;
    .text {
      margin: var(--gutter-xl) var(--gutter-m);
    }
  `}
`

const AboutMore = () => {
  const data = useStaticQuery(graphql`
    query {
      moreImg: file(relativePath: { eq: "about2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const FadeInSection = props => {
    const [isVisible, setVisible] = React.useState(false)
    const domRef = React.useRef()
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.intersectionRatio > 0) {
            setVisible(entry.isIntersecting)
          }
        }
      })

      if (domRef.current.classList.contains('is-visible')) {
        observer.unobserve(domRef.current)
      } else {
        observer.observe(domRef.current)
      }
    }, [])
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    )
  }

  return (
    <AboutMoreCon>
      <Img
        durationFadeIn={250}
        objectFit="cover"
        fluid={data.moreImg.childImageSharp.fluid}
        className="hero-image"
      />

      <FadeInSection>
        <div className="text">
          <HeadingText>HP Gold Partner</HeadingText>
          <BodyText className="smallWidth">
            Quza IT holds a valued premium reseller and distribution status with
            HP in South Africa, concentrating in the extensive dealer, corporate
            and end-user markets. This valued status provides Quza IT with the
            ability to supply original HP products at the best prices.
          </BodyText>
        </div>
      </FadeInSection>
    </AboutMoreCon>
  )
}

export default AboutMore
