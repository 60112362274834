import React, { useContext, useState, useEffect } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'
import StoreContext from '~/context/StoreContext'
import {
  CartCounter,
  MenuLink,
  HeadContainer,
  HeadImg,
  LogoImg,
  Divider,
  MenuItem,
  SearchResults,
  ProductSearchImage,
  SearchLink,
  SearchHeader,
  Button,
  BodyText,
  SearchBlack,
  HeadDivider,
  MenuLinkProd,
} from '../../styles'
import { isMobile } from 'react-device-detect'
import { createFilter } from 'react-search-input'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import Search from '../../images/black-search.webp'
import Logo from '../../images/black-logo.webp'
import WhiteLogo from '../../images/white-logo.webp'
import MobSearch from '../../images/mob-mag.webp'
import WhiteCart from '../../images/whitecart.webp'
import Cart from '../../images/cart.webp'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Burger from './burger'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  });
  return quantity
}

const CUSTOMER_INFO = gql`
  query($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      firstName
    }
  }
`

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const WhiteNavigation = ({ siteTitle }) => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              id
              title
              handle
              createdAt
              productType
              tags
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                sku
                price
              }
            }
          }
        }
      }
    `
  )

  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [search, setSearch] = useState('')
  const { customerAccessToken } = useContext(StoreContext)
  const context = useContext(StoreContext)
  const { checkout } = context.store

  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []))

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  const KEYS_TO_FILTERS = ['node.title']
  const filteredProducts = allShopifyProduct.edges.filter(
    createFilter(search, KEYS_TO_FILTERS)
  )

  const shownProducts = filteredProducts.splice(0, 3)

  const openSearchBar = () => {
    setModal(true)
  }
  const closeSearchBar = () => {
    setModal(false)
  }

  const updateSearch = () => {
    document.getElementById('search_form').submit()
  }

  const navigateProducts = () => {
    navigate('/products')
    context.updateFilterType('all')
  }

  return (
    <>
      {!isMobile && !modal && (
        <HeadContainer>
          <div className="logo">
            <MenuLink className="noHover" href="/">
              <LogoImg src={WhiteLogo} alt="logo" />
            </MenuLink>
          </div>
          <div className="menu-items">
            <MenuLink className="white" href="/">
              Home
            </MenuLink>
            <MenuLinkProd className="white" onClick={navigateProducts}>
              Our Products
            </MenuLinkProd>
            <MenuLink className="white" href="/about">
              About Us
            </MenuLink>
            <MenuLink className="white" href="/contact">
              Contact
            </MenuLink>
            <HeadDivider />
            <MenuLink className="thin white" onClick={openSearchBar}>
              Search
            </MenuLink>

            {customerAccessToken && (
              <Query
                query={CUSTOMER_INFO}
                variables={{
                  customerAccessToken: customerAccessToken.accessToken,
                }}
              >
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <MenuLink href="/account/login" className="white thin">
                        Sign In
                      </MenuLink>
                    )
                  if (error)
                    return (
                      <MenuLink href="/account/login" className="white thin">
                        Sign In
                      </MenuLink>
                    )

                  return (
                    <>
                      {data.customer && data.customer.firstName && (
                        <MenuLink href="/account" className="white thin">
                          Hi, {data.customer.firstName}
                        </MenuLink>
                      )}{' '}
                      {data.customer && !data.customer.firstName && (
                        <MenuLink href="/account" className="white thin">
                          Hi there
                        </MenuLink>
                      )}{' '}
                      {!data.customer && (
                        <MenuLink href="/account/login" className="white thin">
                          Sign In
                        </MenuLink>
                      )}{' '}
                    </>
                  )
                }}
              </Query>
            )}
            {!customerAccessToken && (
              <MenuLink href="/account/login" className="white thin">
                Sign In
              </MenuLink>
            )}
            <MenuLink className="white noHover" href="/cart">
              {quantity > 0 && <CartCounter></CartCounter>}
              <HeadImg src={WhiteCart} alt="cart" />
            </MenuLink>
          </div>
        </HeadContainer>
      )}
      {isMobile && (
        <HeadContainer>
          <div></div>
          <div className="logo">
            <MenuLink href="/">
              <LogoImg className="mobNav" src={Logo} alt="logo" />
            </MenuLink>
          </div>
          <div className="mobMenu">
            <MenuItem className="searchLink" onClick={openSearchBar}>
              <img src={MobSearch} alt="search" />
            </MenuItem>
            <MenuLink className="cartLink" href="/cart">
              {quantity > 0 && <CartCounter></CartCounter>}
              <HeadImg src={Cart} alt="cart" />
            </MenuLink>
            <Burger open={open} setOpen={setOpen} />
          </div>
        </HeadContainer>
      )}
      <Divider className="nav" />
      {modal && (
        <>
          <SearchHeader>
            {!isMobile && (
              <MenuLink className="noHover" href="/">
                <LogoImg src={Logo} alt="logo" />
              </MenuLink>
            )}
            <form
              action="../searchresults"
              method="GET"
              id="search_form"
              autoComplete="off"
            >
              {!isMobile && <img src={Search} alt="search" />}
              <input
                className="input is-large"
                name="value"
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Search Quza IT"
              />
            </form>

            <button
              className="modal-close is-large"
              onClick={closeSearchBar}
              aria-label="close"
            >
              &#x2715;
            </button>
          </SearchHeader>
          <Divider />
          {search && (
            <>
              <SearchResults className={search ? 'showing' : ''}>
                <BodyText className="large black">Suggested Items</BodyText>
                {shownProducts.map(product => {
                  return (
                    <>
                      <SearchLink
                        className="light"
                        to={`/product/${product.node.handle}/`}
                        key={product.node.id}
                      >
                        <ProductSearchImage
                          fluid={
                            product.node.images[0].localFile.childImageSharp
                              .fluid
                          }
                          alt={product.node.handle}
                        />
                        <div className="result">
                          <p>{product.node.title}</p>
                        </div>
                      </SearchLink>
                      <Divider className="spaced" />
                    </>
                  )
                })}
                <div className="more">
                  <Button onClick={updateSearch} className="text">
                    <p className="blue">&#9654;</p>
                    View All Results
                  </Button>
                </div>
              </SearchResults>
            </>
          )}
          <SearchBlack></SearchBlack>
        </>
      )}
    </>
  )
}

WhiteNavigation.propTypes = {
  siteTitle: PropTypes.string,
}

WhiteNavigation.defaultProps = {
  siteTitle: ``,
}

export default WhiteNavigation
