import React from 'react'
import styled from 'styled-components'
import { HeadingText, BodyText } from '../../styles'
import media from '../../styles/media'
 

const EasierCon = styled.div`
  display: block;
  margin: var(--gutter-m) var(--gutter-s);
  .fade-in-section {
    opacity: 0;
    transform: translateY(5vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  ${media.mobileL`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
  ${media.laptop`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: var(--gutter-xl) var(--gutter-m);
  `}
`

const FadeInSection = (props) => {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          setVisible(entry.isIntersecting)
        }
      }
    });

    if (domRef.current.classList.contains('is-visible')) {
      observer.unobserve(domRef.current);
      
    } else {
      observer.observe(domRef.current);
      
    }
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

const AboutEasier = () => {
  return (
    <EasierCon>
      <FadeInSection >
        <HeadingText className="xsmallWidth black easier">
          We want to make life easier for you
        </HeadingText>
      </FadeInSection>
      <FadeInSection >
        <BodyText className="medWidth black easier">
          Quza IT provides services maximizing business productivity, reducing
          the risk associated with bad service and late deliveries. Quza IT
          offers a comprehensive set of products and services ranging from all
          major printing consumables, toners, ink cartridges in both original
          and compatibles to the distribution of printers &amp; copiers,
          hardware sales&amp; support.
        </BodyText>
      </FadeInSection>
    </EasierCon>
  )
}

export default AboutEasier
