import React from 'react'
import styled from 'styled-components'
import { HeadingText, BodyText } from '../../styles'
import media from '../../styles/media'

const MoreCon = styled.div`
  padding: 1px var(--gutter-s) var(--gutter-m);
  background-color: var(--black);
  .fade-in-section {
    opacity: 0;
    transform: translateY(2vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  ${media.laptop`
    padding: var(--gutter-l) var(--gutter-m) 200px;
  `}
`

const BodyCon = styled.div`
  display: block;
  ${media.mobileL`
    p {
      max-width: 500px;
    }
  `}
  ${media.laptop`
    /* margin-top: var(--gutter-m); */
    display: grid;
    grid-template-columns: 1fr 1fr;
    p {
      margin: 0 10% 0 0;
    }
  `}
`

const AboutLarge = () => {
  const FadeInSection = props => {
    const [isVisible, setVisible] = React.useState(false)
    const domRef = React.useRef()
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.intersectionRatio > 0) {
            setVisible(entry.isIntersecting)
          }
        }
      })

      if (domRef.current.classList.contains('is-visible')) {
        observer.unobserve(domRef.current)
      } else {
        observer.observe(domRef.current)
      }
    }, [])
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    )
  }

  return (
    <MoreCon>
      <FadeInSection>
        <HeadingText>We take pride in our offering</HeadingText>
        <BodyCon>
          <BodyText>
            Quza takes pride in offering fast, efficient and professional
            service at all times, as well as being committed to the distribution
            of quality products. A same day delivery service is also available
            for clients who need their products delivered as a matter of
            urgency.
            <br />
            <br />
            Our aim is to utilize our core competencies to make your business
            vision a reality. We have 100% HDI Status &amp; also supply to most
            of the Government Departments.
          </BodyText>
          <BodyText>
            Quza IT achieved HP Silver Partner in the year of 2016, enabling us
            to provide even better pricing to our valued clients. Quza IT
            achieved the same status again for 2017. After another year of
            exceptional growth, we made HP Partner First Gold - Supplies for the
            year of 2018.
          </BodyText>
        </BodyCon>
      </FadeInSection>
    </MoreCon>
  )
}

export default AboutLarge
