import React from 'react'
import AboutHero from './hero'
import AboutEasier from './easier'
import AboutMore from './more'
import AboutLarge from './large'

const About = () => {
  return (
    <>
      <AboutHero />
      <AboutEasier />
      <AboutMore />
      <AboutLarge />
    </>
  )
}

export default About
