import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { HeadingText, BodyText } from '../../styles'
import media from '../../styles/media'
import WhiteNavigation from '../Navigation/whiteNav'

const AboutHeroCon = styled.div`
height: 550px;
  overflow: hidden;
  .fade-in-section {
    opacity: 0;
    transform: translateY(2vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  .hero-image {
    position: absolute !important;
    overflow: hidden;
    width: 100%;
    height: 550px;
    z-index: -1;
  }
  .text {
    margin: var(--gutter-l) var(--gutter-s);
  }
  ${media.mobileL`
    height: 100vh;
    .hero-image {
      width: 100vw;
      height: 100vh;
    }
    .text {
      margin: var(--gutter-m) var(--gutter-s);
    }
  `}
  ${media.laptop`
    margin-top: -66px;
    height: 100vh;
    .hero-image {
      position: absolute !important;
      overflow: hidden;
      width: 100%;
      height: 100vh;
      z-index: -1;
    }
    .text {
      margin: var(--gutter-xxl) var(--gutter-m);
    }
  `}
`

const AboutHero = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(relativePath: { eq: "about1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const FadeInSection = props => {
    const [isVisible, setVisible] = React.useState(false)
    const domRef = React.useRef()
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.intersectionRatio > 0) {
            setVisible(entry.isIntersecting)
          }
        }
      })

      if (domRef.current.classList.contains('is-visible')) {
        observer.unobserve(domRef.current)
      } else {
        observer.observe(domRef.current)
      }
    }, [])
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    )
  }

  return (
    <>
      <WhiteNavigation />
      <AboutHeroCon>
        <Img
          durationFadeIn={250}
          objectFit="cover"
          fluid={data.heroImg.childImageSharp.fluid}
          className="hero-image"
        />

        <FadeInSection>
          <div className="text">
            <HeadingText className="aboutHeadWidth">
              Quza offers a comprehensive set of products and services
            </HeadingText>

            <BodyText className="aboutBodWidth">
              We are reinventing how ideas come to life by making printing more
              personal, convenient, reliable and efficient, so you can keep
              reinventing the ways to engage and express yourself.
            </BodyText>
          </div>
        </FadeInSection>
      </AboutHeroCon>
    </>
  )
}

export default AboutHero
